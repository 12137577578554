import React from 'react'
import personalPhoto from 'images/personal_photo.jpg' 
import SocialMediaTab from '../components/SocialMediaTab'

export default function About() {
    return(
        <div className = 'max-w-5xl mt-20 mx-auto flex'>
            <div className = 'w-1/3'>
                <img className = 'rounded-full mx-auto' src = {personalPhoto} height = {300} width = {300} /> 
                <SocialMediaTab /> 
            </div>
            <div className = 'flex flex-col p-4 w-2/3'>
                <div className = 'h-10 font-bold text-xl mt-2'>
                    A researcher and University Teacher
                </div>
                <div className='text-justify mt-2 p-2'>
                I am a Post-doc  Fellow at the Japanese National Institute for Physiological sciences (NIPS - Japan) and an Assistant Professor of Physiology at South Valley University (Egypt). I did my Ph.D. training at Kyushu University (Japan).
                My previous work involved investigation of immune and inflammatory responses specially in nervrous tissue. Recently, I work in connectomics and computational analysis of neuroglia in large volume Electron Microscopy (vEM).
                </div>
            </div>
        </div> 
    )
}