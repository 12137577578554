import { root } from 'postcss/lib/postcss'
import React from 'react' 
import ReactDom from 'react-dom/client' 
import App from  './App' 
import { BrowserRouter as Router } from 'react-router-dom'

document.addEventListener('DOMContentLoaded', () => {
    const rootElement = document.body.appendChild(document.createElement('div')) 
    const root = ReactDom.createRoot(rootElement) 

    root.render(
        <Router>
            <App />
        </Router>
    )

})