import React from 'react' 
import {Link} from 'react-router-dom' 

export default function Header() {
    const navbarItemStyle = 'w-40 cursor-pointer text-gray-600'
    return(
        <>
            <nav className='fixed flex justify-center items-center left-1/2 -translate-x-1/2 o top-0 shadow-md h-16  bg-gray-100   w-screen min-w-600 max-w-7xl '>
                <div className = {navbarItemStyle}><Link to = "/about"> About me</Link></div>
                {/* <div className = {navbarItemStyle}><Link to = "/projects"> Projects</Link></div> */}
                <div className = {navbarItemStyle}><Link to = "/publications"> Publications</Link></div>
            </nav>
        </>
    )
}