import React from "react";

export default function Publication({
    title,
    authors,
    journal,
    volume,
    issue,
    pages,
    year
}) {
    return(
        <div className="flex flex-col border border-1 border-gray-1 rounded-md mt-2 max-w-600 p-2">
            <div className="text-xl  text-indigo-500">{title}</div>
            <div className="text-gray-500">{authors}</div>
            <div className="text-gray-500">{journal} {volume}{issue && `(${issue})`}{pages && `, ${pages}`}{year && `, ${year}`}</div>
        </div>
    )
}