import React from 'react'
import { useNavigate } from 'react-router'

export default function SocialMediaTab() {
    const navigate = useNavigate() 

   return(
        <div className = 'flex items-center justify-center  mt-2 h-10'>
            {/* facebook */}
            {/* <svg 
                height = {28} 
                width = {28}
                className = 'ml-2 fill-current text-gray-500 cursor-pointer'
                role="img" 
                viewBox="0 0 24 24" 
                xmlns="http://www.w3.org/2000/svg"
                onClick = {(event) => {
                    event.preventDefault() 
                    window.location.href = "https://www.facebook.com/mohammed.youssef.7334/"
                }}    
            >
                    <title/>
                    <path d="M22.676 0H1.324C.593 0 0 .593 0 1.324v21.352C0 23.408.593 24 1.324 24h11.494v-9.294H9.689v-3.621h3.129V8.41c0-3.099 1.894-4.785 4.659-4.785 1.325 0 2.464.097 2.796.141v3.24h-1.921c-1.5 0-1.792.721-1.792 1.771v2.311h3.584l-.465 3.63H16.56V24h6.115c.733 0 1.325-.592 1.325-1.324V1.324C24 .593 23.408 0 22.676 0"/>
            </svg> */}

           {/* X */}
            <svg
                height = {29}
                width = {29} 
                xmlns="http://www.w3.org/2000/svg" 
                shapeRendering="geometricPrecision" 
                textRendering="geometricPrecision" 
                imageRendering="optimizeQuality" 
                fillRule="evenodd" 
                clipRule="evenodd" 
                viewBox="0 0 512 509.64"
                className = 'ml-2 fill-current text-gray-500 cursor-pointer'    
                onClick = {(event) => {
                    event.preventDefault() 
                    window.open('https://x.com/youssef_mys', '_blank')
                }}
                >
                <rect width="512" height="509.64" rx="115.61" ry="115.61"/>
                <path fill="#fff" fillRule="nonzero" d="M323.74 148.35h36.12l-78.91 90.2 92.83 122.73h-72.69l-56.93-74.43-65.15 74.43h-36.14l84.4-96.47-89.05-116.46h74.53l51.46 68.04 59.53-68.04zm-12.68 191.31h20.02l-129.2-170.82H180.4l130.66 170.82z"/>
            </svg> 

            {/* ResearchGate */}
            <svg 
                viewBox="0 0 448 512" 
                height = {32}
                width = {32}
                xmlns="http://www.w3.org/2000/svg"
                className = 'ml-2 fill-current text-gray-500 cursor-pointer'    
                onClick = {(event) => {
                    event.preventDefault() 
                    window.open( 'https://www.researchgate.net/profile/Mohammed-Youssef-2', '_blank') 
                }}
            >
                
                <path d="M0 32v448h448V32H0zm262.2 334.4c-6.6 3-33.2 6-50-14.2-9.2-10.6-25.3-33.3-42.2-63.6-8.9 0-14.7 0-21.4-.6v46.4c0 23.5 6 21.2 25.8 23.9v8.1c-6.9-.3-23.1-.8-35.6-.8-13.1 0-26.1.6-33.6.8v-8.1c15.5-2.9 22-1.3 22-23.9V225c0-22.6-6.4-21-22-23.9V193c25.8 1 53.1-.6 70.9-.6 31.7 0 55.9 14.4 55.9 45.6 0 21.1-16.7 42.2-39.2 47.5 13.6 24.2 30 45.6 42.2 58.9 7.2 7.8 17.2 14.7 27.2 14.7v7.3zm22.9-135c-23.3 0-32.2-15.7-32.2-32.2V167c0-12.2 8.8-30.4 34-30.4s30.4 17.9 30.4 17.9l-10.7 7.2s-5.5-12.5-19.7-12.5c-7.9 0-19.7 7.3-19.7 19.7v26.8c0 13.4 6.6 23.3 17.9 23.3 14.1 0 21.5-10.9 21.5-26.8h-17.9v-10.7h30.4c0 20.5 4.7 49.9-34 49.9zm-116.5 44.7c-9.4 0-13.6-.3-20-.8v-69.7c6.4-.6 15-.6 22.5-.6 23.3 0 37.2 12.2 37.2 34.5 0 21.9-15 36.6-39.7 36.6z"/>
            </svg>
            {/* google scholar */}
            <svg 
                xmlns="http://www.w3.org/2000/svg"
                aria-label="Google Scholar" 
                role="img"
                viewBox="0 0 512 512"
                height = {30}
                width = {30}
                className='ml-2 fill-current text-gray-500 cursor-pointer'   
                onClick = {(event) => {
                    event.preventDefault() 
                    window.open("https://scholar.google.com/citations?user=1N8hVK0AAAAJ", '_blank')
                }} 
            >
                    <rect
                        width="512" height="512"
                        rx="15%"
                        />
                    <path fill="#ffffff" d="M213 111l-107 94h69c5 45 41 64 78 67-7 18-4 27 7 39-43 1-103 26-103 67 4 45 63 54 92 54 38 1 81-19 90-54 4-35-10-54-31-71-23-18-28-28-21-40 15-17 35-27 39-51 2-17-2-28-6-43l45-38-1 16c-3 2-5 6-5 9v103c2 13 22 11 23 0V160c0-3-2-7-5-8v-25l16-16zm58 141c-61 10-87-87-38-99 56-11 83 86 38 99zm-5 73c60 13 61 63 10 78-44 9-82-4-81-30 0-25 35-48 71-48z"/>
            </svg>
        </div>

    )
}