import React, {useEffect} from 'react'
import Publication from '../components/publication'
import citations from '../citations' 
import { object } from 'prop-types'

export default function Publications() {

    useEffect(()=>{

    })
    const renderPublications = ()=> {
        let publications = []
        for(let i in citations['Authors'])
            {
                let publication = {}
                for(let k in citations){
                    publication[k] = citations[k][i]
                }
                publications.push(publication)
            }   
        const renderedPublications = publications.map((e, i) => {
            return(
                <Publication 
                    key = {i}
                    authors = {e.Authors}
                    issue = {e.Number} 
                    journal = {e.Publication}
                    title = {e.Title}
                    volume = {e.Volume} 
                    year = {e.Year} 
                    pages = {e.Pages}
                />
            )
        })

        return renderedPublications
    }
    return(
        <div className = 'max-w-5xl mt-20 mx-auto flex flex-col'>
           {renderPublications()}
        </div> 
    )
}