import React from 'react' 
import Header from './components/Header'

import {Route, Routes} from 'react-router-dom'

// paths
import About from './paths/About' 
import Projects from './paths/Projects' 
import Publications from './paths/Publications' 


export default function App(){
    return(
        <div>
            <Header />
            <Routes>
                <Route exact path = '/' element = {<About />} /> 
                <Route exact path = '/about' element = {<About /> } /> 
                <Route exact path = '/projects' element = {<Projects /> } /> 
                <Route exact path = '/publications' element = {<Publications /> } /> 
            </Routes>
        </div>
    )
}